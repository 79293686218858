<template>
    <div></div>
</template>

<script>
//TODO
    export default {
        name: "topic",
        components: {},
        data: () => {
            return {}
        },
        created() {},
        methods: {}
    }
</script>
<style lang="scss" scoped></style>
